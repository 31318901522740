import React, {useState} from 'react';
import { FormattedMessage } from "react-intl";

import LandingFooter from "../partials/LandingFooter";
import LandingHeader from "../partials/LandingHeader";
import AppointmentApi from "../api/AppointmentApi";
import ApiCaller from "../lib/ApiCaller";
import {is} from "../utils/validation";
import toast from "../utils/toast";
import Modal from "../utils/Modal";
import Thanks from "../partials/Thanks";
import {Link} from "react-router-dom";


const PilotInAlbi = () => {


  const [name,setName] = useState('');
  const [lastname,setlastName] = useState('');
  const [topic,setTopic] = useState('');
  const [type,setType] = useState('');
  const [email,setEmail] = useState('');
  const [message,setMessage] = useState('');
  const [check,setCheck] = useState(false);
  const [notSended,setnotSended] = useState(true);
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const sendEmail = async (e:any) => {
    e.preventDefault();
    const api = new AppointmentApi(new ApiCaller());
    const userId =  'anonymous'
    is.email(email) && console.log(is.email(email) )

    if (email==='' ||  name==='' || type==='') {
      toast.error('Por favor, introduce los campos que faltan.');
      return;
    }
    if (!check) {
      toast.error('Por favor, acepta las condiciones.') ;
      return;
    }

    await api.sendRequest({type:'rapuzelfy-request', name:name, request:type+':'+message, email:email },userId).then((resp:any) => {
      setnotSended(false);
      setVideoModalOpen(true);
      toast.success('Genial! Ya te hemos enviado un email.')
    }).catch(err => {
      toast.error('Ya hemos enviado un email a esta cuenta.')
    });
  }

  return ( <> { notSended && <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <LandingHeader />

      {/*  Page content */}
      <main className="flex-grow">

        {/* Contact section */}
        <section>
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Section header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1"><FormattedMessage id="pilotin.title"/></h1>
              </div>

              {/* Contact form */}
              <form className="max-w-xl mx-auto">
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="first-name"><FormattedMessage id="pilotin.fname"/> <span className="text-red-600">*</span></label>
                    <input id="first-name" type="text" className="form-input w-full text-gray-800" placeholder="Enter your first name" required  value={name} onChange={(e:any)=>{setName(e.target.value)}}/>
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="last-name"><FormattedMessage id="pilotin.lname"/> <span className="text-red-600">*</span></label>
                    <input id="last-name" type="text" className="form-input w-full text-gray-800" placeholder="Enter your last name" required  value={lastname} onChange={(e:any)=>{setlastName(e.target.value)}}/>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email"><FormattedMessage id="pilotin.email"/> <span className="text-red-600">*</span></label>
                    <input id="email" type="email" className="form-input w-full text-gray-800" placeholder="Enter your email address" required value={email} onChange={(e:any)=>{setEmail(e.target.value)}}/>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="subject"><FormattedMessage id="pilotin.subject"/> <span className="text-red-600">*</span></label>
                    <input id="subject" type="text" className="form-input w-full text-gray-800" placeholder="How can we help you?" required value={topic} onChange={(e:any)=>{setTopic(e.target.value)}}/>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="country"><FormattedMessage id="pilotin.you0"/></label>
                    <select id="country" className="form-select w-full text-gray-500" onChange={(e:any)=>{setType(e.target.value)}}>
                      <option></option>
                      <option>Customer</option>
                      <option>Salon</option>
                      <option>Studio</option>
                      <option>Freelance</option>
                    </select>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="message"><FormattedMessage id="pilotin.message"/></label>
                    <textarea id="message" rows={4} className="form-textarea w-full text-gray-800" placeholder="Why is that interesting for you?" value={message} onChange={(e:any)=>{setMessage(e.target.value)}}></textarea>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="flex items-center">
                      <input type="checkbox" className="form-checkbox border-red-500" onChange={(e:any)=>{setCheck(e.target.checked)}}/>
                      <span className="text-gray-500 ml-2 "><FormattedMessage id="pilotin.ok"/></span>
                    </label>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mt-4">
                  <div className="w-full px-3">
                    <button disabled={is.email(email)} onClick={sendEmail} className="btn text-white bg-blue-600 hover:bg-blue-700 w-full"><FormattedMessage id="pilotin.send"/></button>
                  </div>
                </div>
                <div className="text-sm text-gray-600 mt-4">
                <FormattedMessage id="pilotin.consent"/>
                  <Link className="underline" to="/terms"><FormattedMessage id="pilotin.terms"/></Link>
                  <FormattedMessage id="pilotin.and"/>
                  <Link className="underline" to="/terms"><FormattedMessage id="pilotin.policy"/></Link>.
                </div>
              </form>

            </div>
          </div>
        </section>

      </main>

      {/*  Site footer */}
      <LandingFooter />

    </div> }

        <Modal id="modal" ariaLabel="modal-headline" show={videoModalOpen} handleClose={() => setVideoModalOpen(false)}>
          <div className="relative pb0">
            <Thanks/>
          </div>
        </Modal>

    </>
  );
}

export default PilotInAlbi;
