import React from 'react';
import { FormattedMessage } from "react-intl";

function AboutStory() {
  return (
    <section>
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">

          <div className="max-w-3xl mx-auto">

            <h3 className="h3 mb-3"><FormattedMessage id="about.story.title"/> </h3>
            <p className="text-lg text-gray-600 mb-8">
             <FormattedMessage id="about.story.p1"/>
            </p>
            <p className="text-lg text-gray-600 mb-8">
            <FormattedMessage id="about.story.p2"/>
            </p>
          </div>

          <div className="sm:flex">
            <figure className="flex flex-shrink-0 max-w-none sm:max-w-xs lg:max-w-none mb-8 sm:mb-0">
              <img className="flex-grow self-start rounded" src={require('../images/rapunfriends.jpeg')} width="435" height="326" alt="About us 02" />
            </figure>
            <div className="sm:ml-8 lg:ml-16">
              <h4 className="h4 mb-2"><FormattedMessage id="about.story.title2"/></h4>
              <p className="text-lg text-gray-600 mb-8">
              <FormattedMessage id="about.woman.p1"/>
                </p>
              <div className="flex  mb-8">
                <img className="rounded-full self-start flex-shrink-0 shadow-lg mr-4" src={require('../images/about-logo.png')} width="40" height="40" alt="Logo" />
                <div>
                  <blockquote className="text-gray-600 italic">
                  <FormattedMessage id="about.citacion"/>
                  </blockquote>
                  <div className="text-sm font-medium text-gray-600 mt-2">
                    <cite className="text-gray-900 not-italic">Tracy Sun</cite> · <a className="text-blue-600" href="#0">New Markets</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-3xl mx-auto">
            <p className="text-lg text-gray-600">
            <FormattedMessage id="about.woman.p2"/>
            </p>
          </div>

        </div>
      </div>
    </section>
  );
}

export default AboutStory;
