class User{
    id?:string
    name: string;
    email: string;
    token: string;
    type: string;
    status: string;
    stripeId: string;

    constructor(name: string, email: string, token: string, type: string, status?: string, stripeId?: string, _id?: string) {
        this.name = name;
        this.email = email;
        this.token = token;
        this.type = type;
        this.status = status ? status : "";
        this.stripeId = stripeId ? stripeId : "";
        this.id = _id ? _id : "";
    }

    load(data: any): User {
        return new User(data.name, data.email, data.token, data.type, data.status, data.stripeId, data._id);
    }

    toObject(): any {
        return { name: this.name, email: this.email, type: this.type, stripeId: this.stripeId, status: this.status };
    }

    isEmpty(): boolean {
        return this.email == undefined;
    }
}

export default User
