import React, {useEffect, useState} from 'react';
import ClaimNewsletter from "./ClaimNewsletter";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../store/store";
import {SessionState, updateReady, updateSession} from "../store/sessionStore";
import Modal from "../utils/Modal";

const HeroClaim = () => {

    const ready = useSelector((state: AppState) => state.session.ready);
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        setVideoModalOpen(!ready);
        console.log(ready)
    },[]);

    const onClose = () => {
        dispatch(updateReady(true));
        setVideoModalOpen(false);
        console.log(ready);
    }

  return ( <>

          <Modal id="modal" ariaLabel="modal-headline" show={videoModalOpen} handleClose={onClose}>
              <div className="relative pb0">
                  <ClaimNewsletter/>
              </div>
          </Modal>
      </>
  );
}

export default HeroClaim;
