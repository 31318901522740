import ApiCaller from '../lib/ApiCaller';

class AppointmentApi {
    apiCaller: ApiCaller;
    constructor(apiCaller:ApiCaller) {
        this.apiCaller = apiCaller;
    }

    sendRequest(payload:any, userId?:string): Promise<void | any | undefined> {
        return this.apiCaller.call('/v1/appointment/sendRequest', 'POST', {...payload, userId: userId, topic:''}).then(file=> {
           return file;
        });
    }


}

export default AppointmentApi;
