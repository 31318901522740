import React from 'react';
import {SessionState, updateReady, updateSession} from "../store/sessionStore";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import { FormattedMessage } from 'react-intl';

const ClaimNewsletter = () => {

  const dispatch = useDispatch();

  return (
  
  <section>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        <div className="pt-12 md:pt-20 border border-gray-900 bg-gray-800">
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16" data-aos-id-cta>

  {/* Section header */}
    <h1 className="text-3xl md:text-4xl text-white font-extrabold leading-tighter tracking-tighter mb-4" data-aos="fade-up" data-aos-anchor="[data-aos-id-cta]">
    <FormattedMessage id="claimnewsletter.header"/>
    </h1>
      <p className="text-xl text-white mb-8" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-cta]">
      <FormattedMessage id="claimnewsletter.subclaim"/>
      </p>
           
  {/* CTA button */}
    <div className="flex justify-center mb-8" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-cta]">
      <Link className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" to="/pilot-in-albi" onClick={()=>{dispatch(updateReady(true))}}><FormattedMessage id="claimnewsletter.button"/></Link>
    </div>

  {/* Check list */}
    <ul className="flex flex-wrap justify-center text-lg text-gray-400 -mx-2 -my-1 hidden md:flex md:flex-grow">
       <li className="flex items-center mx-3 my-1" data-aos="fade-up" data-aos-delay="600" data-aos-anchor="[data-aos-id-cta]">
          <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
          </svg>
          <span><FormattedMessage id="claimnewsletter.1"/></span>
        </li>
        <li className="flex items-center mx-3 my-1" data-aos="fade-up" data-aos-delay="800" data-aos-anchor="[data-aos-id-cta]">
          <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
          </svg>
          <span><FormattedMessage id="claimnewsletter.2"/></span>
        </li>
        <li className="flex items-center mx-3 my-1" data-aos="fade-up" data-aos-delay="1000" data-aos-anchor="[data-aos-id-cta]">
          <svg className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
          </svg>
          <span><FormattedMessage id="claimnewsletter.3"/></span>
        </li>
        </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClaimNewsletter;
