import { Plugins } from "@capacitor/core";

const { Storage } = Plugins;

export default {
  write: async function write(key: string, value: any): Promise<void> {
    return await Storage.set({
      key: key,
      value: JSON.stringify(value)
    });
  },
  
  read: async function read(key: string): Promise<any> {
    const item = await Storage.get({ key: key });
    return JSON.parse(item.value || 'false');
  },
  
  clear: async function clear(key: string): Promise<void> {
    return await Storage.remove({
      key: key
    });
  }
}