

//------------  state definition
export interface SessionState {
    loggedIn: boolean,
    ready: boolean,
    lang: string
}

//------------ actions
export const UPDATE_SESSION = 'UPDATE_SESSION'
export const UPDATE_LANG = 'UPDATE_LANG'
export const UPDATE_READY = 'UPDATE_READY'

interface UpdateSessionAction {
  type: typeof UPDATE_SESSION
  payload: SessionState
}

export function updateSession(newSession: SessionState): SessionActionTypes {
    return {
      type: UPDATE_SESSION,
      payload: newSession
    }
}

interface UpdateLangAction {
    type: typeof UPDATE_LANG
    payload: string
}

export function updateLang(lang: string): SessionActionTypes {
    return {
        type: UPDATE_LANG,
        payload: lang
    }
}

interface UpdateReadyAction {
    type: typeof UPDATE_READY
    payload: boolean
}

export function updateReady(r: boolean): SessionActionTypes {
    return {
        type: UPDATE_READY,
        payload: r
    }
}

export type SessionActionTypes = UpdateSessionAction | UpdateLangAction | UpdateReadyAction

//------------ reducer
const initialState: SessionState = {
    loggedIn: false,
    ready: false,
    lang: "fr"
}

export function sessionReducer( state = initialState, action: SessionActionTypes): SessionState {
  switch (action.type) {
    case UPDATE_SESSION: {
      return {
        ...state,
        ...action.payload
      }
    }
    case UPDATE_LANG: {
        return {
            ...state,
            lang: action.payload
        }
    }
    case UPDATE_READY: {
        return {
            ...state,
            ready: action.payload
        }
    }
    default:
      return state
  }
}
