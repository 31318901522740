import React, {useEffect, useState} from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import {Sticky} from 'sticky-ts';
//import focusHandling  from 'cruip-js-toolkit';

import Landing from './pages/Landing';
import Home from './pages/Home';
import Tutorials from './pages/Tutorials';
import Pricing from './pages/Pricing';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import About from './pages/About';
import Documentation from './pages/Documentation';
import Help from './pages/Support';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import PageNotFound from './pages/PageNotFound';

import {IntlProvider} from "react-intl";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

import {Provider, useSelector} from "react-redux";
import configureStore, {AppState} from "./store/store";
import { withRouter} from "react-router";
import PilotInAlbi from "./pages/PilotInAlbi";
import Toast from "./components/Toast";
import TermsAndLegal from "./pages/TermsAndLegal";


interface IExampleComponentProps {
  location?: any;
  store?: any;
}

const AppWrapper:React.FC<IExampleComponentProps> = ({location,store}) => {
  return (
      <Provider store={store}>
        <App />
      </Provider>
  )
}

const App = ()  => {

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
    const sticky = new Sticky('[data-sticky]');
  });

  useEffect(() => {
    // @ts-ignore
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    // @ts-ignore
    document.querySelector('html').style.scrollBehavior = ''
    // @ts-ignore
    //focusHandling('outline');

  }, [location.pathname]); // triggered on route change

  const messages:any = {'fr': messages_fr, 'en': messages_en};
  const lang = useSelector((state: AppState) => state.session.lang);

  return (
    <>

        <IntlProvider locale={lang} messages={messages[lang]}>
          <Switch>
            <Route exact path="/"><Landing /></Route>
            <Route exact path="/pilot-in-albi"><PilotInAlbi /></Route>
            <Route exact path="/terms" ><TermsAndLegal /></Route>
            <Route exact path="/demo"><Home /></Route>
            <Route path="/tutorials"><Tutorials /></Route>
            <Route path="/pricing"><Pricing /></Route>
            <Route path="/blog"><Blog /></Route>
            <Route path="/blog-post"><BlogPost /></Route>
            <Route path="/about"><About /></Route>
            <Route path="/documentation"><Documentation /></Route>
            <Route path="/support"><Help /></Route>
            <Route path="/signin"><SignIn /></Route>
            <Route path="/signup"><SignUp /></Route>
            <Route path="/reset-password"><ResetPassword /></Route>
            <Route path="*"><PageNotFound /></Route>
          </Switch>
          <Toast/>
        </IntlProvider>

    </>
  );
}

const store = configureStore();
export default withRouter(props => <AppWrapper store={store}/>);
