import React from 'react';

import HeroHome from '../partials/HeroHome';
import FeaturesHome from '../partials/FeaturesHome';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import FeaturesWorld from '../partials/FeaturesWorld';
import News from '../partials/News';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';

import LandingHeader from "../partials/LandingHeader";
import LandingFooter from "../partials/LandingFooter";
import HeroClaim from "../partials/HeroClaim";

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      <LandingHeader />

        <HeroClaim/>

      <main className="flex-grow">

        <HeroHome />
        <FeaturesHome />
        {/*<FeaturesBlocks />*/}
        {/*<FeaturesWorld />*/}
        {/*<News />*/}
        {/*<Cta />*/}

      </main>

      {/*  Site footer */}
      <LandingFooter />

    </div>
  );
}

export default Home;
