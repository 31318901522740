import React from 'react';

import Terms from "../partials/Terms";
import LandingHeader from "../partials/LandingHeader";
import LandingFooter from "../partials/LandingFooter";

function TermsAndLegal() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      <LandingHeader />

      <main className="flex-grow">

        <Terms />

      </main>

      <LandingFooter />

    </div>
  );
}

export default TermsAndLegal;
