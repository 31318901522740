import { sessionReducer } from './sessionStore'
import { combineReducers, createStore } from 'redux'

const appReducer = combineReducers({
  session: sessionReducer
});

export type AppState = ReturnType<typeof appReducer>

export default function configureStore() {
    const store = createStore(appReducer);
    return store;
}
